// https://codeible.com/view/videotutorial/RPeL7Rnim8LCYxZJa2Dz;title=Loading%20and%20Using%20JavaScript%20in%20Angular

var production = window.location.href.includes("eshtriaqar.com");
var ScarabQueue = ScarabQueue || [];
(function (id) {
  if (document.getElementById(id)) return;
  var js = document.createElement("script");
  js.id = id;
  js.src = "//cdn.scarabresearch.com/js/108952DE8AB54ED6/scarab-v2.js";
  var fs = document.getElementsByTagName("script")[0];
  fs.parentNode.insertBefore(js, fs);
})("scarab-js-api");

function go() {
  if (!production) {
    return;
  }
  // Firing the ScarabQueue. Should be the last call on the page, called only once.
  ScarabQueue.push(["go"]);
}

function customerData() {
  if (!production) {
    return;
  }

  ScarabQueue.push(["setCustomerId", String(JSON.parse(localStorage.getItem("User_Basic_Info")).emaryse)]);
}

function emarsysCart(arr) {
  if (!production) {
    return;
  }
  // {item: '2469', price: 75, quantity: 1}
  ScarabQueue.push(["cart", arr]);
  customerData();
}

function emarsysCurrency(selectedCurrency) {
  if (!production) {
    return;
  }
  ScarabQueue.push(["displayCurrency", selectedCurrency]);
  customerData();
}

function emarsysView(unitViewed) {
  if (!production) {
    return;
  }
  ScarabQueue.push(["view", unitViewed]);
  customerData();
}

function emarsysPurchase(orderId, arr) {
  if (!production) {
    return;
  }
  ScarabQueue.push([
    "purchase",
    {
      orderId: orderId,
      items: arr,
    },
  ]);
  customerData();
}

function emarsysEmail(unitViewed) {
  if (!production) {
    return;
  }
  ScarabQueue.push(["setEmail", "visitor@test-mail.com"]);
  customerData();
}
